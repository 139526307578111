import { gql } from '@apollo/client'

export const SIGN_UP = gql`
  mutation SignUp(
    $firstName: String!
    $lastName: String!
    $email: String!
    $username: String!
    $password1: String!
    $password2: String!
  ) {
    register(
      firstName: $firstName
      lastName: $lastName
      email: $email
      username: $username
      password1: $password1
      password2: $password2
    ) {
      success
      errors
      token
      refreshToken
    }
  }
`

export const SIGN_IN = gql`
  mutation SignIn($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      success
      errors
      token
      refreshToken
    }
  }
`

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refresh: String!) {
    refreshToken(refreshToken: $refresh) {
      token
      success
      errors
    }
  }
`

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmail($email: String!) {
    resendActivationEmail(email: $email) {
      success
      errors
    }
  }
`

export const VERIFY_ACCOUNT = gql`
  mutation VerifyAccount($token: String!) {
    verifyAccount(token: $token) {
      success
      errors
    }
  }
`

export const RESET_PASSWORD_EMAIL = gql`
  mutation SendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation PasswordReset(
    $newPassword1: String!
    $newPassword2: String!
    $token: String!
  ) {
    passwordReset(
      token: $token
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    ) {
      success
      errors
    }
  }
`

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($bio: String) {
    updateProfile(input: { bio: $bio }) {
      success
      errors
    }
  }
`
