import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'

export default function Loading() {
  const user = useSelector((state) => state.user)
  return (
    <div className="w-100 py-5 text-center">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
      {!user ? <h1>please login</h1> : null}
    </div>
  )
}
