import apolloClient from '../../graphql'
import * as mutations from '../graphql/mutations/user.mutations'

export async function signIn(email, password) {
	const res = await apolloClient.mutate({
		mutation: mutations.LOGIN,
		variables: { email, password },
	})
	return res.data.tokenAuth
}

export async function refreshToken(refresh) {
	const res = await apolloClient.mutate({
		mutation: mutations.REFRESH_TOKEN,
		variables: { refresh },
	})
	return res.data.refreshToken
}

export async function revokeToken(refresh) {
	const res = await apolloClient.mutate({
		mutation: mutations.REVOKE_TOKEN,
		variables: { refresh },
	})
	return res.data.refreshToken
}

export async function register(user) {
	const res = await apolloClient.mutate({
		mutation: mutations.REGISTER,
		variables: { ...user },
	})
	return res.data.register
}

export function isUserAuthenticated() {
	const token = localStorage.getItem('access_token')
	const refresh = localStorage.getItem('refresh_token')
	return !!(token && refresh)
}

export async function changePassword(oldPassword, newPassword1, newPassword2) {
	let res = await apolloClient.mutate({
		mutation: mutations.CHANGE_PASSWORD,
		variables: { oldPassword, newPassword1, newPassword2 },
	})
	return res.data.passwordChange
}

export async function resendLink(email) {
	let res = await apolloClient.mutate({
		mutation: mutations.RESEND_VERIFICATION_EMAIL,
		variables: { email },
	})
	return res.data.resendActivationEmail
}

export async function activate(token) {
	let res = await apolloClient.mutate({
		mutation: mutations.VERIFY_ACCOUNT,
		variables: { token },
	})
	return res.data.verifyAccount
}

export async function resetPasswordEmail(email) {
	let res = await apolloClient.mutate({
		mutation: mutations.RESET_PASSWORD_EMAIL,
		variables: { email },
	})
	return res.data.sendPasswordResetEmail
}

export async function passwordReset(token, newPassword1, newPassword2) {
	let res = await apolloClient.mutate({
		mutation: mutations.RESET_PASSWORD,
		variables: { token, newPassword1, newPassword2 },
	})
	return res.data.passwordReset
}
